<template>
  <div>
    <div class="cars-bg-ats">
      <div class="layout">
        <v-container class="cont">
          <v-row align="center" justify="center" class="inner-cont" no-gutters>
            <!-- <v-col cols="12"> -->
            <v-card class="pa-5 pt-10 rounded-xl">
              <carSearch />
            </v-card>
            <!-- </v-col> -->
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import carSearch from '../components/carSearch.vue'
export default {
  title: '-Cars search',
  components: {
    carSearch
  }
}
</script>

<style>
.cont,
.inner-cont {
  height: calc(100vh - 65px);
}
.cars-bg {
  height: 100%;
  min-height: calc(100vh - 65px);
  background: url("https://dev.atnbookingengine.com/images/assets/car.jpeg");
  background-size: cover;
  background-position: center bottom;
}
.cars-bg-ats {
  height: 100%;
  min-height: calc(100vh - 54px);
  background: url("https://dev.atnbookingengine.com/images/assets/car.jpeg");
  background-size: cover;
  background-position: center bottom;
}
.layout {
  height: 100%;
  min-height: calc(100vh - 65px);
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
